<script>
export default {
  name: 'Feedback',
  props: {
    msg: {
      type: Object
    }
  },
  data () {
    return {
      destroyed: false
    }
  },
  computed: {
    getIcon () {
      return `/assets/images/${this.msg.type}.svg`
    }
  },
  methods: {
    close () {
      this.destroyed = true
      this.$store.dispatch('attemptSetHelpMsg', null)
    }
  },
  created () {
    if (this.msg.durationInSec === -1) return
    setTimeout(() => {
      this.close()
    }, (this.msg.durationInSec || 5) * 1000)
  }
}
</script>
<template>
  <v-card elevation="6" class="feedback--card" :class="{'destroyed': destroyed}">
    <v-container>
      <span class="top-right">
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>
      <v-container class="message--body">
        <v-img v-if="msg.type" :width="60" :height="60" :src="getIcon" />
        <div>
          <h5 v-if="msg.title" class="text-left h6 neutral-dark px-4" :class="{'my-auto': !msg.description}">{{ msg.title }}</h5>
          <p v-if="msg.description" class="text-left lato font-16 px-4 mt-1">{{ msg.description }}</p>
        </div>
      </v-container>
    </v-container>
  </v-card>
</template>
<style lang="scss">
  .destroyed {
    display: none !important;
  }
  .feedback--card {
    position: fixed;
    top: 96px;
    right: 50%;
    transform: translateX(50%);
    z-index: 1000;
    width: 600px;
    max-width: 100vw;
    .message--body {
      display: flex;
      .v-image {
        max-width: 60px;
        max-height: 60px;
        .v-image__image {
          background-size: contain;
        }
      }
    }
    .top-right {
      position: absolute;
      right: 0px;
      top: 0px;
    }
  }
</style>
